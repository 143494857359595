<template>
  <div class="mt-12">
    <div>
      <h2 class="mb-10 text-uppercase">
        {{ currentStep == 0 ? 'Direct Invitation' : 'Recommendation' }}
      </h2>
      <h3 class="mb-8">({{ list.length || 0 }})</h3>

      <div class="w-100 mb-12">
        <UserContainerList
          :lists="list"
          :custom="{
            hasCharacteristic: true,
            projectRolesCol: true,
            navigate: '_blank',
            disabledSelect: true
          }"
          hasSelect
          nameKey="display_name"
          photoKey="headshot_file"
          v-model="listId"
          @select="listId = $event"
        ></UserContainerList>
      </div>
      <v-btn block class="btn3" @click="$emit('invite')">
        <v-icon class="mr-4" dark>mdi-plus</v-icon>
        Invite
      </v-btn>
    </div>
    <div class="d-flex justify-center my-12">
      <div class="text-center">
        <v-btn
          class="btn1"
          :class="list.length ? '' : 'outline'"
          :disabled="selectedUser.length <= 0"
          @click="$emit('selectedInvite')"
        >
          {{ list.length ? `Select (${list.length})` : 'Back' }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
  import constantsProjectRoles from '@/filters/constants-project-roles.filter';
  import { mapGetters } from 'vuex';
  import { ROUTE_NAME } from '@/constants';
  import UserContainerList from '@/components/base/UserContainerList';

  export default {
    name: 'InvitePageList',
    components: {
      UserContainerList
    },
    props: ['selectedUser', 'currentStep', 'roleId'],
    data() {
      return {
        list: [],
        listId: [],
        routeName: ROUTE_NAME
      };
    },
    computed: {
      ...mapGetters({
        languagesSpoken: ['constants/languageSpoken'],
        race: ['constants/race']
      })
    },
    methods: {
      getImage(user) {
        return (
          user?.headshot_file?.media_path ||
          this?.$store?.getters['constants/avatarImageUrl']
        );
      },
      getProjectRoles(ids) {
        return ids.length ? constantsProjectRoles(ids, true).join(', ') : '';
      },
      getProfileItems(item) {
        let languages = '';
        if (item.profile.languages_spoken) {
          item.profile.languages_spoken.forEach((x, index, arr) => {
            const language = this.languagesSpoken.find((y) => y.value === x);
            languages += language.description;
            if (index !== arr.length - 1) languages += ', ';
          });
        }

        return [
          {
            title: 'Height',
            value: item.profile.height
          },
          {
            title: 'Race',
            value: item.profile.race
              ? this.race.find((x) => x.value == item.profile.race).description
              : ''
          },
          {
            title: 'Language',
            value: languages
          },
          {
            title: 'Skills',
            value: item.profile.skills
          }
        ];
      }
    },
    mounted() {
      const item = this.selectedUser.find((x) => x.role_id == this.roleId);
      this.list =
        item[this.currentStep == 0 ? 'direct' : 'recommendation'] || [];
      this.listId = this.list.map((x) => x.id);
    }
  };
</script>
<style lang="scss">
  .titleFont {
    font-size: 18px;
  }
  .itemClass {
    padding: 10px 0px;
    border-radius: 3px;
    background-color: #242424;
  }
</style>
