<template>
  <div class="custom-select" :tabindex="tabindex" @blur="open = false">
    <div class="selected" :class="{ open: open }" @click="open = !open">
      {{ getValueDisplay || 'Select a value' }}
    </div>
    <div class="items" :class="{ selectHide: !open }">
      <div
        v-for="(option, i) of options"
        :key="i"
        class="d-flex drop-down px-2 py-2"
        @click="onSelected(option)"
      >
        <div>
          <v-img
            :src="
              getIsPicked(option)
                ? '/assets/image/icons/checkboxSquareActive.png'
                : '/assets/image/icons/checkboxSquare.png'
            "
            width="40"
            contain
            class="mr-3"
          />
        </div>
        {{ option.text || option.title || option.description }}
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'CustomSelectMultiple',
    props: {
      options: {
        type: Array,
        required: true
      },
      value: {
        type: Array,
        required: false,
        default: () => []
      },
      tabindex: {
        type: Number,
        required: false,
        default: 0
      }
    },
    computed: {
      getValue() {
        return this.value || [];
      },
      getValueDisplay() {
        return (
          (this.value || [])
            .map((i) => i.text || i.title || i.description)
            .join(', ') || ''
        );
      }
    },
    methods: {
      getIsPicked(val) {
        return (this.getValue || []).find(
          (i) => i.value.toString() == val.value.toString()
        );
      },
      onSelected(val) {
        let newValue = [];
        if (
          (this.value || []).find(
            (i) => i.value.toString() == val.value.toString()
          )
        ) {
          newValue = (this.value || []).filter(
            (i) => i.value.toString() != val.value.toString()
          );
        } else {
          newValue = (this.value || []).concat([val]);
        }
        this.$forceUpdate();
        return this.$emit('input', newValue);
      }
    },
    data() {
      return {
        open: false
      };
    }
  };
</script>
<style lang="scss" scoped>
  .custom-select {
    position: relative;
    width: 100%;
    text-align: left;
    outline: none;
    height: 47px;
    line-height: 47px;
  }

  .custom-select .selected {
    background-color: transparent;
    /* border-radius: 6px; */
    /* border: 1px solid #666666; */
    color: #fff;
    /* padding-left: 1em; */
    cursor: pointer;
    line-height: 1.5;
    user-select: none;
  }

  .custom-select .selected.open {
    /* border: 1px solid #ad8225;
    border-radius: 6px 6px 0px 0px; */
  }

  .custom-select .selected:after {
    position: absolute;
    content: '';
    top: 5px;
    right: 0.1em;
    width: 0;
    height: 0;
    border: solid white;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }

  .custom-select .items {
    color: #fff;
    border-radius: 0px 0px 6px 6px;
    overflow: hidden;
    /* border-right: 1px solid #ad8225;
    border-left: 1px solid #ad8225;
    border-bottom: 1px solid #ad8225; */
    position: absolute;
    background-color: #0a0a0a;
    left: 0;
    right: 0;
    z-index: 1;
  }

  .custom-select .items div {
    color: #fff;
    padding-left: 1em;
    cursor: pointer;
    user-select: none;
  }

  .custom-select .items div:hover {
    background-color: #ffb6ff;
    color: #ff2ff2;
  }

  .selectHide {
    display: none;
  }
  .drop-down {
    align-items: center;
  }
</style>
