<template>
  <div>
    <div class="d-flex justify-space-between align-center">
      <div class="white--text"><h2>FILTER</h2></div>
      <div class="white--text cursor-pointer" @click="reset">
        <h2>CLEAR</h2>
      </div>
    </div>
    <v-row class="mt-6" justify-md="space-between">
      <template v-for="filterItem in getFilterList">
        <v-col cols="12" sm="6" :key="filterItem.name">
          <h2 class="white--text">{{ filterItem.title }}</h2>

          <br />
          <CustomSelectMultiple
            v-if="filterItem.type == 'mul-select'"
            :options="filterItem.options"
            v-model="filter[filterItem.name]"
          />
          <CustomInput
            v-else-if="filterItem.type == 'tag-input'"
            v-model="filter[filterItem.name]"
            v-bind="filterItem"
            dark
            outlined
          />
          <input
            v-else
            placeholder="Type here"
            style="color: white"
            v-model="filter['age_to_play']"
          />
        </v-col>
      </template>
    </v-row>
    <div class="text-center mt-10">
      <v-btn class="btn1 white--text mt-10" @click="$emit('apply', filter)">
        Apply
      </v-btn>
    </div>
  </div>
</template>

<script>
  import CustomSelectMultiple from '@/components/inputs/CustomSelectMultiple.vue';
  import CustomInput from '@/components/inputs/custom-input.vue';
  export default {
    name: 'DirectInvitationFilter',
    components: { CustomSelectMultiple, CustomInput },
    methods: {
      reset() {
        this.filter = {
          'gender[]': [],
          age_to_play: '',
          'race[]': [],
          'languages_spoken[]': [],
          'ethnicity[]': [],
          'skills[]': { tags: [] },
          'height[]': [],
          'weight[]': { tags: [] },
          'hair_color[]': [],
          'hair_length[]': [],
          'hair_type[]': [],
          'eye_color[]': [],
          'skin_color[]': [],

          'tv_online_rate[]': [],
          'feature_film_rate[]': [],
          'commercial_rate[]': [],
          'variety_rate[]': [],
          'student_project_rate[]': [],
          'corporate_rate[]': [],
          'public_service_announcement_rate[]': [],
          'voiceover_rate[]': []
        };
      }
    },
    computed: {
      getFilterList() {
        return [
          {
            name: 'gender[]',
            title: 'Sex',
            type: 'mul-select',
            options: this?.$store?.getters['constants/genders']
          },
          {
            name: 'age_to_play',
            title: 'ON-SCREEN AGE',
            type: 'text'
          },
          {
            name: 'race[]',
            title: 'RACE',
            type: 'mul-select',
            options: this?.$store?.getters['constants/race']
          },
          {
            name: 'ethnicity[]',
            title: 'ETHNICITY',
            type: 'mul-select',
            options: this?.$store?.getters['constants/countries']
          },

          {
            id: 'skills[]',
            name: 'skills[]',
            label: 'SKILLS',
            type: 'tag-input',
            column: { cols: '12' }
          },
          {
            id: 'weight[]',
            name: 'weight[]',
            label: 'WEIGHT',
            type: 'tag-input',
            column: { cols: '12' }
          },
          {
            name: 'height[]',
            title: 'HEIGHT',
            type: 'mul-select',
            options: this?.$store?.getters['constants/height']
          },
          {
            name: 'languages_spoken[]',
            title: 'LANGUAGE',
            type: 'mul-select',
            options: this?.$store?.getters['constants/languageSpoken']
          },
          {
            name: 'hair_color[]',
            title: 'HAIR COLOR',
            type: 'mul-select',
            options: this?.$store?.getters['constants/hair_color']
          },
          {
            name: 'hair_length[]',
            title: 'HAIR LENGTH',
            type: 'mul-select',
            options: this?.$store?.getters['constants/hair_length']
          },
          {
            name: 'hair_type[]',
            title: 'HAIR TYPE',
            type: 'mul-select',
            options: this?.$store?.getters['constants/hair_type']
          },
          {
            name: 'eye_color[]',
            title: 'EYE COLOR',
            type: 'mul-select',
            options: this?.$store?.getters['constants/eye_color']
          },
          {
            name: 'skin_color[]',
            title: 'SKIN COLOR',
            type: 'mul-select',
            options: this?.$store?.getters['constants/skin_color']
          },
          {
            name: 'tv_online_rate[]',
            title: 'TV ONLINE RATE',
            type: 'mul-select',
            options: this?.$store?.getters['constants/rate']
          },
          {
            name: 'feature_film_rate[]',
            title: 'FEATURE FILM RATE',
            type: 'mul-select',
            options: this?.$store?.getters['constants/rate']
          },
          {
            name: 'commercial_rate[]',
            title: 'COMMERCIAL RATE',
            type: 'mul-select',
            options: this?.$store?.getters['constants/rate']
          },
          {
            name: 'variety_rate[]',
            title: 'VARIETY RATE',
            type: 'mul-select',
            options: this?.$store?.getters['constants/rate']
          },
          {
            name: 'student_project_rate[]',
            title: 'STUDENT PROJECT RATE',
            type: 'mul-select',
            options: this?.$store?.getters['constants/rate']
          },
          {
            name: 'corporate_rate[]',
            title: 'CORPORATE',
            type: 'mul-select',
            options: this?.$store?.getters['constants/rate']
          },
          {
            name: 'public_service_announcement_rate[]',
            title: 'PUBLIC SERVICE ANNOUNCEMENT RATE',
            type: 'mul-select',
            options: this?.$store?.getters['constants/rate']
          },
          {
            name: 'voiceover_rate[]',
            title: 'VOICEOVER RATE',
            type: 'mul-select',
            options: this?.$store?.getters['constants/rate']
          }
        ];
      }
    },
    data: () => ({
      options: [{ title: 'All', value: '' }],
      filter: {
        'gender[]': [],
        age_to_play: '',
        'race[]': [],
        'languages_spoken[]': [],
        'ethnicity[]': [],
        'skills[]': { tags: [] },
        'height[]': [],
        'weight[]': { tags: [] },
        'hair_color[]': [],
        'hair_length[]': [],
        'hair_type[]': [],
        'eye_color[]': [],
        'skin_color[]': [],
        'tv_online_rate[]': [],
        'feature_film_rate[]': [],
        'commercial_rate[]': [],
        'variety_rate[]': [],
        'student_project_rate[]': [],
        'corporate_rate[]': [],
        'public_service_announcement_rate[]': [],
        'voiceover_rate[]': []
      }
    })
  };
</script>
<style lang="scss">

</style>
