<template>
  <div>
    <AppSearchBar
      v-model="search"
      :search="searchInput"
      class="mt-10"
    />
    <div>
      <div class="d-flex justify-space-between">
        <h3 class="mb-8">({{ selected.length || 0 }})</h3>
        <div>
          <v-icon
            dark
            @click="$openModal('directTalentFilter')"
          >
            mdi-tune
          </v-icon>
        </div>
      </div>
      <div class="w-100 mb-12">
        <UserContainerList
          :searching="searching"
          :lists="users"
          :custom="{
            hasCharacteristic: true,
            projectRolesCol: true,
            navigate: '_blank'
          }"
          hasSelect
          nameKey="display_name"
          photoKey="headshot_file"
          v-model="selected"
          @select="selected = $event"
        ></UserContainerList>
      </div>
    </div>
    <div v-observe-visibility="scrolledToBottom"></div>
    <div class="d-flex justify-center my-12 select-btn_wrapper" id="bottomSelectBtn">
      <div class="text-center">
        <v-btn
          class="btn1"
          :disabled="selected.length <= 0"
          @click="onClickSelect"
        >
          Select
        </v-btn>
        <div class="underlineBtn mt-7" @click="$emit('back')">Back</div>
      </div>
    </div>
    <ModalBase id="directTalentFilter" width="900"
      ><DirectInvitationFilter @apply="applyFilter"
    /></ModalBase>
  </div>
</template>

<script>
  import { CREATE_JOB } from '@/api';
  import DirectInvitationFilter from './DirectInvitationFilter.vue';
  import ModalBase from '@/components/base/ModalBase';
  import { mapGetters } from 'vuex';
  import { ROUTE_NAME } from '@/constants';
  import constantsProjectRoles from '@/filters/constants-project-roles.filter';
  import UserContainerList from '@/components/base/UserContainerList';

  export default {
    name: 'TalentList',
    props: ['selectedUser', 'roleId'],
    components: { 
      DirectInvitationFilter, 
      ModalBase, 
      UserContainerList 
    },
    computed: {
      ...mapGetters({
        languagesSpoken: ['constants/languageSpoken'],
        race: ['constants/race']
      })
    },
    methods: {
      async loadTalentListData(query) {
        this.$startLoading();
        this.searching = true;
        try {
          const data = await CREATE_JOB.getTalentList({
            search: this.search || '*',
            per_page: this.perPage,
            cursor: this.nextCursor,
            ...query
          });
          this.users = data?.data?.items.map((x) => {
            return {
              ...x,
              project_role_ids: x.project_role_ids || [],
              project_role_type_ids: x.project_role_type_ids || []
            };
          });
          this.searching = false;
          this.nextCursor = data?.data?.pagination?.next_cursor;
          this.perPage = data?.data?.pagination?.per_page || 10;
          this.$closeModal();
        } catch (e) {
          console.error(e);
          this.openAppDialogInfo(
            'error',
            e.error_message || 'Request Failed',
            '',
            [
              {
                text: 'OK',
                action: () => {
                  this.closeAppDialogInfo();
                }
              }
            ]
          );
        }
        this.$stopLoading();
      },
      onClickSelect() {
        const users = this.users.filter((x) => this.selected.includes(x.id));
        this.$emit('select', users);
      },
      applyFilter(query) {
        this.loadTalentListData(query);
      },
      getImage(user) {
        return (
          user?.headshot_file?.media_path ||
          this?.$store?.getters['constants/avatarImageUrl']
        );
      },
      getProjectRoles(ids) {
        return ids.length ? constantsProjectRoles(ids, true).join(', ') : '';
      },
      getProfileItems(item) {
        let languages = '';
        if (item.profile.languages_spoken) {
          item.profile.languages_spoken.forEach((x, index, arr) => {
            const language = this.languagesSpoken.find((y) => y.value === x);
            languages += language.description;
            if (index !== arr.length - 1) languages += ', ';
          });
        }

        return [
          {
            title: 'Height',
            value: item.profile.height
          },
          {
            title: 'Race',
            value: item.profile.race
              ? this.race.find((x) => x.value == item.profile.race).description
              : ''
          },
          {
            title: 'Language',
            value: languages
          },
          {
            title: 'Skills',
            value: item.profile.skills
          }
        ];
      },
      searchInput() {
        this.$debounceHandler(() => {
          if (this.search.length > 2 || this.search.length == 0) {
            this.loadTalentListData()
          }
        });
      },
      async scrolledToBottom(isVisible) {
        const bottomSelectBtn = document.getElementById('bottomSelectBtn');
        if (!isVisible) {
          if (bottomSelectBtn.classList.contains('isBottom')) bottomSelectBtn.classList.remove('isBottom');
          return;
        }
        if (!bottomSelectBtn.classList.contains('isBottom')) bottomSelectBtn.classList.add('isBottom');
      }
    },
    mounted() {
      this.selected = this.selectedUser
        .find((x) => x.role_id == this.roleId)
        .direct.map((d) => d.id);
      this.loadTalentListData();
    },
    data: () => ({
      users: [],
      search: '',
      nextCursor: null,
      perPage: 1000,
      debounce: null,
      debounceMilliseconds: 500,
      selected: [],
      routeName: ROUTE_NAME,
      searching: false
    })
  };
</script>
<style lang="scss" scoped>

  .select-btn_wrapper {
    position: sticky;
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -ms-sticky;
    position: -o-sticky;
    bottom: -1px;
    padding: 1rem 0;
  }
  .select-btn_wrapper:not(.isBottom) {
    background-color: #000;
    z-index: 100;

  }

</style>
