<template>
  <div v-if="!roleId">
    <h1 class="pageHeader my-12 text-uppercase">
      {{
        $t(`label.${currentStep == 0 ? 'directInvitation' : 'recommendation'}`)
      }}
    </h1>
    <p v-if="invited.length <= 0 && currentStep == 0">
      If you have any talents in mind, use direct invite to invite the talents.
      If not, press skip to use filter to filter talents who might be suitable
    </p>
    <h3 class="white--text mt-12 mb-6">Invite For:</h3>
    <div v-if="roles.length">
      <div
        v-for="role in roles"
        :key="role.id"
        class="role-list-item"
        @click="setupInvite(role)"
      >
        <div>
          <v-img
            v-if="isInvited(role)"
            src="/assets/image/icons/checkboxActive.png"
            width="30"
            class="mr-3"
          />
          <v-icon v-else dark class="mr-3">mdi-chevron-right</v-icon>
        </div>

        {{ role.character }}
      </div>
    </div>
    <div v-else class="role-list-item" @click="setupInvite(null)">
      <div> 
        <v-img
          v-if="isInvited(null)"
          src="/assets/image/icons/checkboxActive.png"
          width="30"
          class="mr-3"
        />
        <v-icon v-else dark class="mr-3">mdi-chevron-right</v-icon>
      </div>
      {{ $t('label.all') }}
    </div>
    <div class="text-center btn-holder2">
      <!-- <v-btn
        v-if="currentStep == 0"
        :class="'btn1 ' + (invites.direct.length > 0 ? '' : 'outline')"
        @click="nextStep"
      > -->
      <v-btn
        v-if="currentStep == 0"
        :class="'btn1 ' + (invites.direct.length > 0 ? '' : 'outline')"
        @click="proceedInvite"
      >
        <!-- {{ invites.direct.length > 0 ? 'Next' : 'Skip' }} -->
        {{ invites.direct.length > 0 ? 'Send Invite' : 'Skip' }}
      </v-btn>
      <!-- <template v-else>
        <div class="mb-6">
          <v-btn
            :class="
              'btn1 ' + (invites.recommendation.length > 0 ? '' : 'outline')
            "
            @click="proceedInvite"
          >
            {{ invites.recommendation.length > 0 ? 'Send Invite' : 'Skip' }}
          </v-btn>
        </div>
        <div class="">
          <a class="underlineBtn white--text" @click="$emit('back')"> Back </a>
        </div>
      </template> -->
    </div>
  </div>
  <div v-else>
    <TalentList
      v-if="isSearching && currentStep == 0"
      :selectedUser="selectedUser"
      :roleId="roleId"
      @back="isSearching = false"
      @select="selectUser"
    />
    <RecommendationTalentList
      v-if="isSearching && currentStep == 1"
      :selectedUser="selectedUser"
      :roleId="roleId"
      @back="isSearching = false"
      @select="selectUser"
    />
    <InvitePageList
      v-if="!isSearching"
      :selectedUser="selectedUser"
      :currentStep="currentStep"
      :roleId="roleId"
      @invite="isSearching = true"
      @selectedInvite="roleId = null"
    />
  </div>
</template>
<script>
  import { CREATE_JOB } from '@/api';

  import TalentList from './TalentList.vue';
  import InvitePageList from './InvitePageList.vue';
  import RecommendationTalentList from './RecommendationTalentList.vue';

  export default {
    name: 'DirectInvitation',
    props: ['currentStep'],
    components: {
      TalentList,
      InvitePageList,
      RecommendationTalentList
    },
    watch: {
      isSearching() {
        this.$scrollToTop();
      },
      roleId() {
        this.$scrollToTop();
      }
    },
    methods: {
      async loadJobData(id) {
        this.$startLoading();
        try {
          const data = await CREATE_JOB.getSingleJobData(id);
          this.roles = data?.data?.casting_roles || [];
          if (this.roles?.length) {
            this.roles.forEach((r) => {
              this.selectedUser.push({
                role_id: r.id,
                direct: [],
                recommendation: []
              });
            });
          } else {
            this.selectedUser.push({
              role_id: 'all',
              direct: [],
              recommendation: []
            });
          }
        } catch (e) {
          console.error(e);
          this.openAppDialogInfo(
            'error',
            e.error_message || 'Request Failed',
            '',
            [
              {
                text: 'OK',
                action: () => {
                  this.closeAppDialogInfo();
                }
              }
            ]
          );
        }
        this.$stopLoading();
      },
      selectUser(e) {
        const roleId = this.roleId;
        let item = this.selectedUser.find((x) => x.role_id == roleId);
        const type = ['direct', 'recommendation'];
        if (!item) {
          this.selectedUser.push({
            role_id: roleId,
            direct: this.currentStep == '0' ? e : [],
            recommendation: this.currentStep == '0' ? [] : e
          });
        } else {
          item[this.currentStep == '0' ? 'direct' : 'recommendation'] = e;
        }
        this.selectedUser.forEach((x) => {
          type.forEach((t) => {
            this.invites[t] = this.invites[t].concat(x[t]);
          });
        });
        this.isSearching = false;
      },
      proceedInvite() {
        if(this.invites.direct.length) this.$openModal(
            'messageModal',
            'Proceed to invite?',
            {
              title: 'Proceed',
              action: () => this.sendInvitation()
            },
            { show: true, customText: '' }
          );

        else this.$emit('next', this.invites);
        
      },
      async sendInvitation() {
        this.$startLoading();
        try {
          this.selectedUser.forEach(async (x) => {
            const body = {
              casting_job_id: this?.$route?.query?.job_id,
              casting_role_id: x.role_id == 'all' ? null : x.role_id,
              // user_id: x.direct.concat(x.recommendation).map((u) => u.id)
              user_id: x.direct.map((u) => u.id)
            };
            if (body.user_id.length) {
              await CREATE_JOB.sendInvitation(body);
            }
            x = {
              ...x,
              direct: [],
              recommendation: []
            };
          });

          this.$closeModal();
          this.$scrollToTop();

          this.$emit('next', this.invites);
        } catch (e) {
          console.error(e);
          this.openAppDialogInfo(
            'error',
            e.error_message || 'Request Failed',
            '',
            [
              {
                text: 'OK',
                action: () => {
                  this.closeAppDialogInfo();
                }
              }
            ]
          );
        }
        this.$stopLoading();
      },
      setupInvite(role = null) {
        this.roleId = role?.id || 'all';
      },
      isInvited(role = null) {
        const roleId = role?.id || 'all';
        const item = this.selectedUser.find((x) => x.role_id == roleId);
        if(!item) return false;
        return (
          item[this.currentStep == 0 ? 'direct' : 'recommendation']?.length || 0
        );
      },
      isRecommendationInvited(role) {
        return this.invited.includes(role.id.toString());
      },
      nextStep() {
        this.$emit('next', this.invites);
      }
    },
    mounted() {
      if (this?.$route?.query?.job_id) {
        this.loadJobData(this?.$route?.query?.job_id);
      }
    },
    data: () => ({
      roles: [],
      jobId: null,
      roleId: null,
      invites: {
        direct: [],
        recommendation: []
      },
      invited: [],
      isSearching: false,
      selectedUser: []
    })
  };
</script>

<style lang="scss">
  .role-list-item {
    font-size: 1.2rem;
    cursor: pointer;
    display: flex;
    align-items: center;

    &:hover {
      text-shadow: 0 0 white;
    }
  }
</style>
