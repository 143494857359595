import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my-12"},[_c('h2',{staticClass:"py-7 text-uppercase"},[_vm._v("Recommendation")]),_c('div',[_c('div',{staticClass:"d-flex justify-space-between"},[_c('h3',{staticClass:"mb-8"},[_vm._v("("+_vm._s(_vm.users.length || 0)+")")]),_c('div',[_c(VIcon,{attrs:{"dark":""},on:{"click":function($event){return _vm.$openModal('recommendationFilter')}}},[_vm._v(" mdi-tune ")])],1)]),_c('div',{staticClass:"w-100 mb-12"},[_c('UserContainerList',{attrs:{"lists":_vm.users,"custom":{
          hasCharacteristic: true,
          projectRolesCol: true,
          navigate: '_blank'
        },"hasSelect":"","nameKey":"display_name","photoKey":"headshot_file"},on:{"select":function($event){_vm.selected = $event}},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)]),_c('div',{staticClass:"d-flex justify-center my-12"},[_c('div',{staticClass:"text-center"},[_c(VBtn,{staticClass:"btn1",attrs:{"disabled":_vm.selectedUser.length <= 0},on:{"click":_vm.onClickSelect}},[_vm._v(" Select ")]),_c('div',{staticClass:"underlineBtn mt-7",on:{"click":function($event){return _vm.$emit('back')}}},[_vm._v("Back")])],1)]),_c('ModalBase',{attrs:{"id":"recommendationFilter","width":"900"}},[_c('RecommendationFilter',{on:{"apply":_vm.applyFilter}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }