import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-12"},[_c('div',[_c('h2',{staticClass:"mb-10 text-uppercase"},[_vm._v(" "+_vm._s(_vm.currentStep == 0 ? 'Direct Invitation' : 'Recommendation')+" ")]),_c('h3',{staticClass:"mb-8"},[_vm._v("("+_vm._s(_vm.list.length || 0)+")")]),_c('div',{staticClass:"w-100 mb-12"},[_c('UserContainerList',{attrs:{"lists":_vm.list,"custom":{
          hasCharacteristic: true,
          projectRolesCol: true,
          navigate: '_blank',
          disabledSelect: true
        },"hasSelect":"","nameKey":"display_name","photoKey":"headshot_file"},on:{"select":function($event){_vm.listId = $event}},model:{value:(_vm.listId),callback:function ($$v) {_vm.listId=$$v},expression:"listId"}})],1),_c(VBtn,{staticClass:"btn3",attrs:{"block":""},on:{"click":function($event){return _vm.$emit('invite')}}},[_c(VIcon,{staticClass:"mr-4",attrs:{"dark":""}},[_vm._v("mdi-plus")]),_vm._v(" Invite ")],1)],1),_c('div',{staticClass:"d-flex justify-center my-12"},[_c('div',{staticClass:"text-center"},[_c(VBtn,{staticClass:"btn1",class:_vm.list.length ? '' : 'outline',attrs:{"disabled":_vm.selectedUser.length <= 0},on:{"click":function($event){return _vm.$emit('selectedInvite')}}},[_vm._v(" "+_vm._s(_vm.list.length ? ("Select (" + (_vm.list.length) + ")") : 'Back')+" ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }