<template>
  <div>
    <div v-if="false" class="headerStep" :class="currentStep == steps.length - 1 ? 'active' : ''">
      <div class="container">
        <div class="headerScroll d-flex">
          <div
            v-for="(step, i) in steps"
            :key="i"
            :class="'headerItem noClickAction twoStep ' + (i <= currentStep ? 'active' : '')"
          >
            {{ step }}
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <DirectInvitation
        :currentStep="currentStep"
        @next="proceedToNextStep($event)"
      />
      <!-- <DirectInvitation
        :currentStep="currentStep"
        @next="proceedToNextStep($event)"
        @back="currentStep -= 1"
      /> -->
    </div>
  </div>
</template>

<script>
  import DirectInvitation from './InvitationPage/DirectInvitation.vue';

  export default {
    name: 'Invitation',
    components: { DirectInvitation },
    data: () => ({
      currentStep: 0,
      steps: ['Direct Invitation', 'Recommendation'],
      directInvitationList: [],
      recommendationList: [],
      invites: {
        direct: [],
        recommendation: []
      }
    }),
    watch: {
      currentStep() {
        this.$scrollToTop();
      }
    },
    methods: {
      proceedToNextStep(e) {
        this.invites = e;
        // if (this.currentStep == 0) {
        //   this.currentStep = 1;
        // } else {
          this.$router.push({
            name: 'create_job_invitation_done',
            params: {
              skipInvitation:
                !this.invites.direct.length
              // skipInvitation:
              //   !this.invites.direct.length &&
              //   !this.invites.recommendation.length
            }
          });
        // }
      }
    }
  };
</script>
<style lang="scss"></style>
